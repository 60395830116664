import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { StatusValues, ValuePropertyBag, KPI, KPIValueBag } from '../../models/speed-monitor.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-run-mode',
  templateUrl: './run-mode.component.html',
  styleUrls: ['./run-mode.component.scss']
})
export class RunModeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public valuePropertyBag: ValuePropertyBag;
  @Input() public kpiValueBag: KPIValueBag;

  @Input()
  public get speed(): number {
    return this.valuePropertyBag.value;
  }
  public set speed(value: number) {
    if (!(value >= 0)) {
      value = 0;
    }
    if (this.valuePropertyBag) {
      this.valuePropertyBag.value = value;
      this.setBackgroundColor();
    }
  }
  public unit: string;
  public targetSpeed: number;
  public status: string;
  public backgroundColorClass = 'run-notargetspeed-background-color';
  public lastDisplayTime: number;
  public kpis: KPI[] = [];
  public currentKpiIndex = 0;
  public currentKpi: KPI;
  public shtUnit: string;
  public pcsUnit: string;
  public mUnit: string;

  constructor(private translate: TranslateService) {
    this.translate.get(['SPEED_MONITOR.SHT_UNIT', 'SPEED_MONITOR.PCS_UNIT', 'SPEED_MONITOR.M_UNIT']).subscribe((values) => {
      Object.keys(values).map((key) => {
        switch (key) {
          case 'SPEED_MONITOR.SHT_UNIT':
            this.shtUnit = values[key];
            break;
          case 'SPEED_MONITOR.PCS_UNIT':
            this.pcsUnit = values[key];
            break;
          case 'SPEED_MONITOR.M_UNIT':
            this.mUnit = values[key];
            break;
        }
      });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.valuePropertyBag) {
      if (changes.valuePropertyBag.currentValue.value) {
        this.setBackgroundColor();
      }
    }
    if (changes.kpiValueBag) {
      this.kpis = changes.kpiValueBag.currentValue.kpiValue;
      if (this.kpis && this.kpis.length > 0) {
        this.setKpiValues();
      }
    }
  }

  public ngOnInit() {
    this.setBackgroundColor();
    if (history.state.flag) {
      this.kpiValueBag.kpiValue = history.state.kpiList;
    }

    this.kpis = this.kpiValueBag.kpiValue;

    if (this.kpis && this.kpis.length > 0) {
      this.setKpiValues();
    }
    this.setUnit();
  }

  /* Set Background Color */
  public setBackgroundColor(): void {
    this.status = this.setStatus();
    if (this.valuePropertyBag && this.valuePropertyBag.targetValue) {
      this.targetSpeed = this.valuePropertyBag.targetValue;
    }
    if ((this.targetSpeed !== null || this.targetSpeed !== undefined) && this.targetSpeed > 0) {
      if (this.status === StatusValues.OK) {
        this.backgroundColorClass = 'run-highspeed-background-color';
      } else if (this.status === StatusValues.NOK) {
        this.backgroundColorClass = 'run-lowspeed-background-color';
      }
    } else {
      this.backgroundColorClass = 'run-notargetspeed-background-color';
    }
  }

  /* Set WorkCenter Status */
  public setStatus(): string {
    if (this.valuePropertyBag && this.valuePropertyBag.targetValue) {
      this.valuePropertyBag.value >= this.valuePropertyBag.targetValue ? (this.status = StatusValues.OK) : (this.status = StatusValues.NOK);
    }
    return this.status;
  }

  public setKpiValues() {
    this.currentKpi = this.kpis[this.currentKpiIndex];
    if (this.currentKpi.value) {
      if (this.currentKpi.value % 1 !== 0) {
        this.currentKpi.value = Number(this.currentKpi.value.toFixed(2));
      }
    }
  }

  public ngOnDestroy() {}

  // Set Unit
  private setUnit() {
    switch (this.valuePropertyBag.unit) {
      case 'SHT/h':
        this.valuePropertyBag.unit = this.shtUnit;
        break;
      case 'PCS/h':
        this.valuePropertyBag.unit = this.pcsUnit;
        break;
      case 'M/h':
        this.valuePropertyBag.unit = this.mUnit;
        break;
      default:
        this.valuePropertyBag.unit = this.pcsUnit;
        break;
    }
  }
}
