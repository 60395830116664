<section class="chronos-dialog--size">
  <div class="quantity-change-modal">
    <form [formGroup]="modalForm" libEnterKeyHandler="submit_modal" class="quantity-change-modal__container">
      <div class="p-grid">
        <div class="p-col-7">
          <label [for]="'change_quantity_input'" class="col-form-label">
            {{ articleClassification | titlecase }} {{ 'PALLET_CARD.QUANTITY' | translate }}
          </label>
          <lib-input-number
            inputId="change_quantity_input"
            [inputStyle]="INPUT_STYLE"
            [disabled]="disableQuantityInput"
            formControlName="quantity"
          ></lib-input-number>
          {{ quantity.unitId }}
        </div>
        <div *ngIf="isLastContainer || isOuterView" class="p-col-5">
          <lib-button
            [typeClass]="'button--primary'"
            [sizeClass]="'button--small'"
            [loadingTopic]="SUGGEST_QUANTITY_LOADING_TOPIC"
            [label]="'RUN_PHASE.SUGGEST_QTY' | translate"
            (clicked)="onSuggestQuantity()"
          ></lib-button>
        </div>
      </div>
    </form>
    <div *ngIf="isOuterView">
      <div class="outer-view-content-row">
        <div class="outer-view-content-row__left-content">{{ numberofPartialOrFullPallet }}</div>
        <div class="outer-view-content-row__right-content">{{ 'PALLET_CARD.PALLETS' | translate }}</div>
      </div>
      <div class="outer-view-content-row">
        <div class="outer-view-content-row__left-content">{{ numberofOuters }}</div>
        <div class="outer-view-content-row__right-content">{{ 'PALLET_CARD.OUTERS' | translate }}</div>
      </div>
      <div class="outer-view-content-row">
        <div class="outer-view-content-row__left-content">{{ numberofPiecesPerOuter }}</div>
        <div class="outer-view-content-row__right-content">
          {{ 'MACHINE_CHART.PCS_UNIT' | translate }}/{{ 'PALLET_CARD.OUTERS' | translate }}
        </div>
      </div>
      <div class="outer-view-content-row">
        <div class="outer-view-content-row__left-content">{{ numberofPiecesInRestOuter }}</div>
        <div class="outer-view-content-row__right-content">
          {{ 'MACHINE_CHART.PCS_UNIT' | translate }}/{{ 'RUN_PHASE.REST_OUTERS' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isChangeFollowingPalletsEnabled" class="quantity-change-modal__container">
    <p-checkbox [(ngModel)]="changeFollowingPallets" binary="true" [label]="'PALLET_CARD.CHANGE_FOLLOWING' | translate"></p-checkbox>
  </div>
  <div></div>
  <lib-modal-footer
    (submitClicked)="submitModal()"
    (declineClicked)="closeModal()"
    [whenLoadingTopic]="LOADING_TOPIC"
    [submitDisabled]="modalForm.invalid"
  ></lib-modal-footer>
</section>
